
.media-scroller {
    --spacer: 1rem;
    --spacer-sm: .5rem;
    display: grid;
    gap: var(--spacer);
    grid-auto-flow: column;
    grid-auto-columns: 250px;
    padding: 0 var(--spacer) var(--spacer) 0;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
}

/*@media screen and (min-width: 1024px) {*/
/*    .media-scroller {*/
/*        grid-auto-columns: 17%;*/
/*    }*/
/*}*/


.media-element {
    /*display: grid;*/
    grid-template-rows: min-content;
    gap: var(--spacer-sm);
    /*padding: var(--spacer);*/
}

.media-element > img {
    inline-size: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.snaps-inline {
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: var(--spacer, 1rem);
}

.snaps-inline > * {
    scroll-snap-align: start;
}

