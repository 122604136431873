.section-padding {
    @apply py-5;
}

.card {
    @apply rounded-lg bg-white p-5;
}

.section-heading {
    @apply text-3xl font-semibold text-gray-700 p-2;
}


.form-control,
.form-select {
    @apply w-full text-slate-600 border border-slate-300/50 dark:text-white dark:border-slate-700 dark:bg-slate-600 dark:focus:border-slate-900 text-sm;
}

.btn-neutral {
    @apply
    focus:ring
    focus:ring-offset-2
    focus:ring-opacity-40
    active:translate-y-0.5
    transition
    text-white
    rounded-full
    bg-muted
    py-4
    px-7
    focus:outline-none
    text-sm
    uppercase
    tracking-widest
    rounded-full
}

[x-cloak]{
    @apply hidden lg:block
}

[type='text'].form-control,
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
textarea.form-control,
select {
    @apply w-full text-slate-600 border border-slate-300/50 dark:text-white dark:border-slate-700 dark:bg-slate-600 dark:focus:border-slate-900 text-sm;
    @apply
    py-3 px-4
    !outline-none
    !ring-0
    focus:outline-none
    focus:border
    focus:border-blue-300
    peer-placeholder-shown:mt-2
    rounded-md
}
.form-label {
    @apply
    absolute
    left-3.5
    -top-1.5
    text-xs
    px-1
    transition-all
    peer-placeholder-shown:top-3.5
    peer-placeholder-shown:text-sm
    peer-focus:-top-1.5
    peer-focus:text-xs
    cursor-text
    bg-white text-blue-900/40 dark:bg-slate-600 dark:text-gray-300 dark:rounded-sm dark:px-2 dark:peer-focus:pt-1;
}

.form-check {
    @apply inline-flex items-center cursor-pointer text-sm;
}

[type='checkbox'].form-check-input {
    @apply text-blue-500;
    @apply w-5 h-5 mr-2 disabled:opacity-60;
    @apply focus:ring-blue-400 focus:ring-opacity-25;
    @apply border border-gray-300;
    @apply rounded;
}

.form-check-label {
    @apply mr-1;
}

.invalid-feedback{
    @apply text-sm text-red-600;
}

hr.hr{
    @apply my-3 border
}
.filter-heading{
    @apply font-semibold border-b mb-2 mt-3
}
.nav-tabs{
    @apply flex gap-3 pt-3;
}
.nav-tabs .nav-item .active-tab{
    @apply font-semibold;
}
.nav-tabs .nav-link{
    @apply px-2 py-1;
}
.text-danger{
    @apply text-red-600;
}

.btn-link{
    @apply text-secondary hover:underline
}
.red-star{
    @apply text-red-500 font-bold leading-none;
}
.text-link{
    @apply text-primary-dark hover:underline
}

.btn-primary {
    @apply
    py-4
    px-7
    focus:outline-none
    text-sm
    uppercase
    tracking-widest
    rounded-full
    focus:ring
    focus:ring-offset-2
    focus:ring-opacity-40
    active:translate-y-0.5
    transition
    text-white
    rounded-full
    bg-primary
}
.btn-sm-primary {
    @apply
    py-1
    px-7
    focus:outline-none
    text-sm
    uppercase
    tracking-widest
    rounded-full
    focus:ring
    focus:ring-offset-2
    focus:ring-opacity-40
    active:translate-y-0.5
    transition
    text-white
    rounded-full
    bg-primary
}
