@import '../bladewind/css/button.css';
@import '../bladewind/css/datepicker.css';
@import '../bladewind/css/dropdown.css';
@import '../bladewind/css/input.css';
@import '../bladewind/css/popup.min.css';
@import '../bladewind/css/side-nav.css';
@import '../bladewind/css/table.css';
@import "hamburger.css";
@import "scroller.css";
@import "utilities.css";
@import "scrollbar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
